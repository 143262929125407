






















import { ContentItemSummaryResult, ContentsClient } from "@/api/DoceoApi";
import ContentItemSummary from "@/components/contentItems/ContentItemSummary.vue";
import Vue from "vue";

export default Vue.extend({
  components: { ContentItemSummary },
  metaInfo() {
    return {
      link: [{ rel: "canonical", href: `${process.env.VUE_APP_BASE_URL}${this.$route.path}` }],
    };
  },

  props: {
    name: String,
  },

  data() {
    return {
      contentItems: [] as ContentItemSummaryResult[],
      isLoading: false,
    };
  },

  async mounted() {
    this.isLoading = true;
    let itemsLoaded = await this.loadItems();
    this.isLoading = false;
  },

  methods: {
    /**
     * Loads drug results with the given name
     * Returns how many results were loaded
     */
    async loadItems(): Promise<number> {
      const contentsClient = new ContentsClient();
      this.contentItems = await contentsClient.getDrugContentItemsByName(this.name);
      return this.contentItems.length;
    },
  },
});
